import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
import 'chart.js/auto';
import { Pie, Chart, Bar } from "react-chartjs-2";

//Chart.register(ArcElement)
const PieChart = ({txtPassed}) => {

    // setup 
    //console.log(txtPassed)
    let NTA_percent = 100 * parseFloat(txtPassed.NTA)
    let YTA_percent = 100 * parseFloat(txtPassed.YTA)
    let ESH_percent = 100 * parseFloat(txtPassed.ESH)
    let NAH_percent = 100 * parseFloat(txtPassed.NAH)


    let myData = {
        labels: ['NTA', 'YTA', 'ESH', 'NAH'],
        datasets: [{
          label: 'Vote Breakdown on Original Post',
          data: [NTA_percent, YTA_percent, ESH_percent, NAH_percent],
          backgroundColor: [
            'rgb(113, 147, 255, 0.5)',
            "rgb(196, 90, 179, 0.5)",
            "rgb(242, 149, 89, 0.5)",
            'rgb(11, 31, 54, 0.5)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(0, 0, 0, 0.2)'
          ],
          borderColor: [
            'rgb(113, 147, 255, 1)',
            'rgb(196, 90, 179, 1)',
            'rgb(242, 149, 89, 1)',
            'rgb(11, 31, 54, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            'rgba(0, 0, 0, 1)'
          ],
          borderWidth: 1,
          hoverOffset: 10 
        }]
      };
  
    // config 
    const opt = {
            layout: {
                padding: {
                    bottom: 15
                }
            }
          
        }
  
    
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://cdn.jsdelivr.net/npm/chart.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
    <div> 
        <div class="chartCard">
        <div class="chartBox">
            <Pie   
                data={myData}
                options={opt}
            />

            </div>
          
        </div>
    
      <Helmet>
            <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>
        </Helmet>

      
    </div>

    )
}
export default PieChart;
import { motion } from "framer-motion";
import React from 'react';


const ReactButtons = ( {textPassed, responseClickedFlag, setResponseClickedFlag} ) => {

	const [optClick, setOptClicked] = React.useState(0);
	const [choiceCorrectFlag, setChoiceCorrectFlag] = React.useState(false);

	var opt1background = "rgb(242, 149, 89, 0.75)"
	var opt2background = "rgb(196, 90, 179, 0.75)"
	var opt3background = "rgb(113, 147, 255, 0.75)"
	var opt4background = "rgb(11, 31, 54, 0.75)"
	var optGrayscale = "rgb(200,200,200, 0.5)"
	var optRightbackground = "rgb(20,220,10)"
	var optWrongbackground = "rgb(209,43,0)"


	var ESH_width = 250 + Math.max(0, parseFloat(textPassed.ESH_Upvotes / textPassed.Total_Positive_Upvotes)) * 250;
	var YTA_width = 250 + Math.max(0, parseFloat(textPassed.YTA_Upvotes / textPassed.Total_Positive_Upvotes)) * 250;
	var NTA_width = 250 + Math.max(0, parseFloat(textPassed.NTA_Upvotes / textPassed.Total_Positive_Upvotes)) * 250;
	var NAH_width = 250 + Math.max(0, parseFloat(textPassed.NAH_Upvotes / textPassed.Total_Positive_Upvotes)) * 250;

	// evaluate if the choice was correct
    function handleResponesClick(choice) {
        var percents = {
            NTA_percent: textPassed.NTA_Upvotes, 
            YTA_percent: textPassed.YTA_Upvotes, 
            ESH_percent: textPassed.ESH_Upvotes,
			NAH_percent: textPassed.NAH_Upvotes}


        var maxJudgment = "NTA_percent";
        
        // iterate through judgments to find the maximum (the 'correct' judgment)
        // to store in the maxJudgment variable

        for (var prop in percents) {
			//console.log(prop);
            if (percents[maxJudgment] < percents[prop]) {
				//console.log(maxJudgment);
                maxJudgment = prop;
            }
        }

		//console.log(choice);
		
        
        if (choice == maxJudgment) {
            setChoiceCorrectFlag(true);
        }
        else {
            setChoiceCorrectFlag(false);
        }


    }

	return (
		<motion.div className="response" initial= {{opacity: 0}} animate = {{opacity: 1}}>
                <motion.button id="opt1" className="responseButtons" disabled={responseClickedFlag}
					
					/*</motion.div>whileHover={{scale: 1.1, backgroundColor: 'rgb(204, 54, 0, 0.5)'}}*/
					style = {{backgroundColor: (optClick !== 1 && responseClickedFlag === true) ? opt1background: "rgb(242, 149, 89)"}}
					/* The code here first sets width depending if clicked. Then using three if statements checking if clicked then if option 1 then if the choice is correct to set color*/
					animate={{width: responseClickedFlag ? ESH_width: 250, backgroundColor: responseClickedFlag ? optClick === 1 ? choiceCorrectFlag ? optRightbackground: optWrongbackground : optGrayscale : opt1background}}
					onClick={() => {setResponseClickedFlag(true);  setOptClicked(1); setResponseClickedFlag(true); ; handleResponesClick("ESH_percent")}}
				> 
					Everyone Sucks 
				</motion.button>

				<br></br>

                <motion.button id="opt2" className="responseButtons" disabled={responseClickedFlag}
					
					/*</motion.div>whileHover={{scale: 1.1, backgroundColor: 'rgb(184, 0, 31, 0.5)'}}*/
					style = {{backgroundColor: (optClick !== 2 && responseClickedFlag === true) ? opt2background: "rgb(196, 90, 179)"}}
					animate={{width: responseClickedFlag ? YTA_width: 250, backgroundColor: responseClickedFlag ? optClick === 2 ? choiceCorrectFlag ? optRightbackground: optWrongbackground : optGrayscale: opt2background}} 
					onClick={() => {setResponseClickedFlag(true); setOptClicked(2); setResponseClickedFlag(true); handleResponesClick("YTA_percent")}}
				> 
					Asshole 
				</motion.button>

				<br></br>

                <motion.button id="opt3" className="responseButtons" disabled={responseClickedFlag}
					
					/*</motion.div>whileHover={{scale: 1.1, backgroundColor: 'rgb(113, 147, 255, 0.5)'}}*/
					style = {{backgroundColor: (optClick !== 3 && responseClickedFlag === true) ? opt3background: "rgb(127, 207, 182)" }}
					animate={{width: responseClickedFlag ? NTA_width: 250, backgroundColor: responseClickedFlag ? optClick === 3 ? choiceCorrectFlag ? optRightbackground: optWrongbackground : optGrayscale: opt3background}} 
					onClick={() => {setResponseClickedFlag(true); setOptClicked(3); setResponseClickedFlag(true); handleResponesClick("NTA_percent")}}
				> 
					Not the A-hole 
				</motion.button>

				<br></br>

                <motion.button id="opt4" className="responseButtons" disabled={responseClickedFlag}
				style = {{backGroundColor: (optClick !==4 && responseClickedFlag === true) ? opt4background: "rgb(11, 31, 54)"}}
				animate={{width: responseClickedFlag ? NAH_width: 250, backgroundColor: responseClickedFlag ? optClick === 4 ? choiceCorrectFlag ? optRightbackground : optWrongbackground : optGrayscale: opt4background}}
				onClick={() => {setResponseClickedFlag(true); setOptClicked(4); setResponseClickedFlag(true); handleResponesClick("NAH_percent")}}
				> No A-holes here </motion.button>
        </motion.div>
	)
}

export default ReactButtons;
import { motion } from "framer-motion";
import React from 'react';

const StartButton = ( {sIsPressed, sInfoText, ssResponseButtons, textPassed, ssNextButton} ) => {
	
	return (
					<div className = "startButton">
                        <button className= "btn btn-moving-gradient btn-moving-gradient--red"
                                onClick={() => {sIsPressed(true); sInfoText(""); setTimeout(() => {ssResponseButtons(true); ssNextButton(true)}, 1500); 
                                setTimeout(() => {sInfoText(textPassed.text);}, 1000)}}>
                            Ok, I'm ready to play!
                        </button>
                    </div>
	)
}

export default StartButton;
import { motion } from "framer-motion";
import React from 'react';
import PieGraphButton from "./PieGraphButton";

const NextButton = ( {txtPassed, setInfoText, setTxt, responseClickedFlag, setResponseClickedFlag, setOptClicked, setIsClicked} ) => {

	console.log(txtPassed)
	// Fetch a new story from the backend.  Hooked up to the 'skip/next' button.
	function fetchNewStory()
	{
		fetch("https://morning-brushlands-15412.herokuapp.com/http://flaskenv.eba-p9wn9ffc.us-east-1.elasticbeanstalk.com/api").then(response => {
			if(response.ok){
			  return response.json()
			}
	  
		}).then(data => setTxt(data))
	}

	return (
		<motion.div className="nextContainer" initial= {{opacity: 0}} animate = {{opacity: 1}}>

                <button id="optN" className="responseButtons" 
						style = {{
									backgroundColor: responseClickedFlag ? '#EF253C' : "rgb(217, 221, 230)",
									alignSelf: 'stretch',
									flexShrink: 0 // Needed to keep the skip/next button its intended size
								}}

						onClick= {() => {
											fetchNewStory(); 
											setResponseClickedFlag(false); 
											setOptClicked(0); 
											setIsClicked(false);
										}
								}
				> 
					{responseClickedFlag ? 'Next':'Skip'} 
				</button>

				{responseClickedFlag && <PieGraphButton txtPassed = {txtPassed}/>}

        </motion.div>
	)
}

export default NextButton;
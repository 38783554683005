import { motion } from "framer-motion";
import React from 'react';
import ReactButtons from './ReactButtons.js';
import StoryText from './Storytext.js';
import StartButton from './StartButton.js';
import NextButton from "./NextButton.js";
import PieGraphButton from "./PieGraphButton.js";

const Home = ( {txtPassed, setTxt} ) => {
    const [infoText, setInfoText] = React.useState("reddit.com/r/AmITheAsshole is a forum where anyone can post details from an event they were involved in to see if other redditors thought they were in the wrong. In this game, you will be presented with a post from r/AmITheAsshole with at least 50 comments and asked to make your own judgment. After you choose, you will be shown how Reddit judged the situation.")
    const [optClick, setOptClicked] = React.useState(0);

	const [isPressed, setIsPressed] = React.useState(false);
	const [showResponseButtons, setshowResponseButtons] = React.useState(false);
    const [showNextButton, setshowNextButton] = React.useState(false);
    
    // states for storing user choice, animating buttons, and determining if the choice was correct.
    


    const [nextClickedFlag, setNextClickedFlag] = React.useState(false);

    const [responseClickedFlag, setResponseClickedFlag] = React.useState(false)
    


    return ( 
    <div>
	<span className = "homeContainer"> 
		<StoryText text={txtPassed.text} title={txtPassed.title} isPressed={isPressed} />
			{ showResponseButtons && <ReactButtons textPassed={txtPassed} responseClickedFlag = {responseClickedFlag} setResponseClickedFlag={setResponseClickedFlag} setOptClicked={setOptClicked} optClick={optClick}/> }
            { showNextButton && <NextButton txtPassed = {txtPassed} setInfoText = {setInfoText} setTxt = {setTxt} responseClickedFlag = {responseClickedFlag} setResponseClickedFlag={setResponseClickedFlag} setOptClicked={setOptClicked}/> }
    </span>
            {
            !isPressed &&
            <StartButton sIsPressed={setIsPressed} sInfoText={setInfoText} 
                        ssResponseButtons={setshowResponseButtons} ssNextButton={setshowNextButton} 
                        textPassed={txtPassed} />
			}
    </div>
	
	
	/*
        <div className="homeContainer">
            <motion.div className= "Home"
            style={{
                height: isPressed ? "400px" : "200px",
                width: isPressed ? "800px" : "835px"
            }}
            initial={false}
            animate={{
				//Adjust "Response" Div to appear during animation  
				//Spacing is the major thing here
                x: isPressed ? -350: 0,
				width: isPressed ? 800: 835,
				height: isPressed ? 650: 175}}
			transition= {{
				delay: 0,
			width: {type: "spring", duration: 1},
			height: {type: "spring", duration: 1},
			x: {type: "tween", duration: 1},
			}}
				
            >
                <p className= "infoParagraph"> 
                        {infoText}
                </p>
                {
                    !isPressed && 
                    <div>
                        <button className= "btn btn-moving-gradient btn-moving-gradient--red"
                                onClick={() => {setIsPressed(true); setInfoText(""); setTimeout(() => {setshowResponseButtons(true);}, 3000); 
                                setTimeout(() => {setInfoText(txtPassed.name);}, 2000)}}>
                            Ok, I'm ready to play!
                        </button>
                    </div>
                }

                
            </motion.div>
			
            {
				// Add Fade In for Buttons if(isPressed && reponse!null)
            showResponseButtons &&
            <motion.div className="response" initial= {{opacity: 0}} animate = {{opacity: 1}}>
                <button id="opt1" class="responseButtons"> Everyone Sucks </button>
				<br></br>
                <button id="opt2" class="responseButtons"> Asshole </button>
				<br></br>
                <button id="opt3"class="responseButtons"> Not the A-hole </button>
				<br></br>
                <button id="opt4" class="responseButtons"> No A-holes here </button>
            </motion.div>
			
        }
		
        <></>
        </div>
		*/
     );
}
 
export default Home;
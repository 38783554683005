import { motion } from "framer-motion";
import React from 'react';

const Storytext = ({text, title, isPressed}) => {
	
	var defaultTxt = "reddit.com/r/AmITheAsshole is a forum where anyone can post details from an event they were involved in to see if other redditors thought they were in the wrong. In this game, you will be presented with a post from r/AmITheAsshole with at least 50 comments and asked to make your own judgment. After you choose, you will be shown how Reddit judged the situation."
	return (
	<motion.div className= "Home"
            style={{
                height: isPressed ? "65vh" : "200px",
                width: isPressed ? "600px" : "835px",
				marginLeft: isPressed ? '30vw' : 'auto',
				minHeight: isPressed ? '65vh': ''

            }}
            initial={false}
            animate={{
				//Adjust "Response" Div to appear during animation  
				//Spacing is the major thing here
                x: isPressed ? -400: 0,
				width: isPressed ? '40%': 835,
				height: isPressed ? '60%': 175}}
			transition= {{
				delay: 0,
			width: {type: "spring", duration: 1},
			height: {type: "spring", duration: 1},
			x: {type: "tween", duration: 1},
			}}
				
            >
                <motion.p className= "infoParagraph" initial= {{opacity: 0}} animate = {{opacity: 1}}> 
						{isPressed ? <h5>{title}</h5> : ""}
						<br/>
                        {isPressed ? text : defaultTxt}
                </motion.p>

	</motion.div>
				
	)
	
	 
}
export default Storytext;
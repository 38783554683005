import { motion } from "framer-motion";
import React from 'react';
import pieGraphLogo from './images/pie-chart.svg'
import Tooltip from '@mui/material/Tooltip';
import 'reactjs-popup/dist/index.css';
import { Modal, Button} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import PieChart from "./PieChart"
import BarGraph from "./BarGraph"


const PieGraphButton = ({txtPassed}) => {
    console.log(txtPassed)

    const [show, setShow] = React.useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    return (
        <div>
            <Tooltip title="See More Info about Results">
                <button className="pieGraphButton" onClick={handleShow}>
                        <img className= "pieGraphImg" src= {pieGraphLogo} alt="more details"> 
                        </img>
                </button>
            </Tooltip>


            <Modal dialogClassName="modal" show={show}  centered onHide={handleClose}>
                <Modal.Header closeButton >
                <Modal.Title>More Info</Modal.Title>
                </Modal.Header>
                <Modal.Body className = "modalBody">
                <Tabs
                    id = "modalTabs"
                    defaultActiveKey={"bar chart"}
                > 
                    <Tab eventKey="bar chart" title="Bar Chart">
                        <h6>This bar chart shows the percentage of comments on the original reddit post that gave each judgment.</h6>
                            <div>
                                <BarGraph className = "barGraph" txtPassed={txtPassed}/>  
                            </div>
                    </Tab>

                    <Tab eventKey= "pie graph" title= "Pie Graph">
                        <div>
                            <PieChart className = "pieGraph" txtPassed={txtPassed}/>
                        </div>
                    </Tab>

                </Tabs>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default PieGraphButton;
import React, {useState, useEffect} from 'react'
import Navbar from './Navbar'
import Home from './Home'

import greenLogo from './images/logoGreen.png'
import brownLogo from './images/logoBrown.png'


function App() {

  const [txt, setTxt] = useState([])



  useEffect(() => {
    fetch("https://morning-brushlands-15412.herokuapp.com/http://flaskenv.eba-p9wn9ffc.us-east-1.elasticbeanstalk.com/api").then(response => {
      if(response.ok){
        return response.json()
      }

  }).then(data => setTxt(data))
  }, [])

  return (
    
  <div className= "App">
    <div className= "mainWrapper">
      <div className= "pageTitle">
        <img className = "greenLogo" src= {greenLogo} alt="green logo"/>
        <h1>Moral Compass</h1>
        <img className = "brownLogo" src= {brownLogo} alt="brown logo"/>

      </div>

      <Home txtPassed = {txt} setTxt= {setTxt}/>


    </div>
  </div>

  
  );
}

export default App;